<template>
  <div class="growUp">
    <div class="title">成长发育</div>
    <div class="container">
      <div class="left">
        <p class="info">随着年龄的增长，儿童的屈光状态是不断发生变化的（远视-正视-近视），在这个过程中，儿童在屈光度正视之前都表现为正常，即通过简单的测量无法评估儿童眼球发育的速度，实际上要是经过规范的验光，可以发现这些儿童在近视之前就已经表现出来正式化速度过快（眼轴发育过快）的特点。</p>
      </div>
      <div class="right">
        <video style="width: 80%" controls autoplay="autoplay" loop="loop" ref="video">
          <source type="video/mp4">
          <source type="video/ogg">
          您的浏览器不支持Video标签。
        </video>
      </div>
    </div>
    <p class="tips">{{content}}</p>
    <div class="radioGroup" @click="radioClick">
      <label for="1"><input type="radio" name="state" id="1" checked value="1"/>出生</label>
      <label for="2"><input type="radio" name="state" id="2" value="2"/>3-4</label>
      <label for="3"><input type="radio" name="state" id="3" value="3"/>6-8</label>
      <label for="4"><input type="radio" name="state" id="4" value="4"/>9-12</label>
      <label  for="5" class="scene" @click="sceneClick"><img id="5" src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/growth.png" />儿童视力发育表</label>
    </div>
    <div class="btnGroup" >
      <button @click="go" style="display: none">返回</button>
    </div>

    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "growUp",
  data() {
    return {
      list: [],
      videoList: [],
      content: '',
      mvUrl: ''
    }
  },
  methods: {
    radioClick(e) {
      if(e.target.value){
        this.content = this.list[e.target.value - 1];
        this.mvUrl = this.videoList[e.target.value - 1];
      }
    },
    go() {
      this.$router.go(-1);
    },
    sceneClick() {
      this.$router.push({path: '/PopularScienceHome/visualChart'});
    }
  },
  watch: {
    mvUrl(newValue, oldValue) {
      let video = this.$refs.video;
      video.src = this.mvUrl;
    }
  },
  created() {
    let arr = [
        '出生：眼轴长度18.5mm，屈光度+4.00D',
        '3岁：眼轴男性：22.2mm，女性：21.5mm 屈光度男性：+2.33D，女性+2.96D',
        '7-8岁：眼轴22.8mm，屈光度：+1.00D',
        '14岁：眼轴男性：23.1mm 女性：22.7mm，屈光度：男性+0.93D，女性+0.62D'
    ];
    let videoList = [
      'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/birth.mp4',
      'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/birth3_4.mp4',
      'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/birth6_8.mp4',
      'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/video/birth9_12.mp4'
    ]
    this.list = arr;
    this.videoList = videoList;
    this.content = this.list[0];
    this.mvUrl = this.videoList[0];
  }
}
</script>

<style scoped lang="scss">
.growUp {
  width: 100%;
  height: 100%;
  background: url("http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/pedia_background.jpg") no-repeat center center;
  background-size: cover;
}
.title {
  line-height: 40px;
  font-weight: 600;
  font-size: 18px;
}
.container {
  width: 100%;
  display: flex;
  align-items: center;
}
.left {
  flex: 1;
  width: 100%;
  padding-left: 10px;
}
.right {
  flex: 1;
}
.left .info {
  text-align: left;
  padding: 5px 20px;
  font-size: 16px;
  line-height: 22px;
}
.tips {
  margin-top: 30px;
}
.radioGroup {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioGroup label {
  margin: 0 25px;
  display: flex;
  align-items: center;
}
.radioGroup input {
  margin-right: 5px;
  font-size: 40px;
}
.scene img {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.btnGroup {
  display: flex;
  justify-content: center;
}
.btnGroup button {
  margin: 50px;
  padding: 10px 0;
  width: 210px;
  border: 1px solid #333;
  color: #333;
  background: transparent;
  font-size: 18px;
}

</style>
